import React from "react";
import "./slides.css";
import Title from "./Title";

const Postbiotics = (slide) => {
  function toggle() {
    document
      .getElementById("central-media-container")
      .classList.toggle("fade-out");
    document.getElementById("primary").classList.toggle("is-visible");
  }
  const scienceOne = slide.data.slides[6].info1;
  const scienceTwo = slide.data.slides[6].info2;
  const scienceThree = slide.data.slides[6].info3;
  const scienceFour = slide.data.slides[6].info4;
  const scienceFive = slide.data.slides[6].info5;
  const footnote1 = slide.data.slides[6].footnote;

  const petOne = slide.data.slides[7].info1;
  const petTwo = slide.data.slides[7].info2;
  const petThree = slide.data.slides[7].info3;
  const petFour = slide.data.slides[7].info4;
  const footnote2 = slide.data.slides[7].footnote;

  const handleClick = () => {
    const primarySlide = document.getElementById("primary");
    const secondarySlide = document.getElementById("secondary");
    primarySlide.classList.toggle("hide");
    primarySlide.classList.toggle("is-visible");
    secondarySlide.classList.toggle("hide");
    secondarySlide.classList.toggle("is-visible");
  };
  return (
    <div className="page-container">
      <Title {...slide} />
      <div className="media-container" id="primary">
        <button className="switch-panel left" onClick={toggle}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
            alt="previous page"
          />
        </button>
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {scienceOne}
            </li>
            {scienceTwo ? (
              <li className="list-item" id="list-item-two">
                {scienceTwo}
              </li>
            ) : null}
            {scienceThree ? (
              <li className="list-item" id="list-item-three">
                {scienceThree}
              </li>
            ) : null}
            {scienceFour ? (
              <li className="list-item" id="list-item-four">
                {scienceFour}
              </li>
            ) : null}
            {scienceFive ? (
              <li className="list-item" id="list-item-five">
                {scienceFive}
              </li>
            ) : null}
          </ul>
          <p id="footnote">{footnote1}</p>
        </div>
        <div className="science-image-container">
          <img
            className="science-image"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/lactobacillus.png"
            alt="lactobacillus"
          />

          <button className="switch-panel right" onClick={handleClick}>
            <img
              src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
              alt="next page"
            />
          </button>
        </div>
      </div>
      <div className="media-container" id="secondary">
        <button className="switch-panel left" onClick={handleClick}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
            alt="previous page"
          />
        </button>
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {petOne}
            </li>
            {petTwo ? (
              <li className="list-item" id="list-item-two">
                {petTwo}
              </li>
            ) : null}
            {petThree ? (
              <li className="list-item" id="list-item-three">
                {petThree}
              </li>
            ) : null}
            {petFour ? (
              <li className="list-item" id="list-item-four">
                {petFour}
              </li>
            ) : null}
          </ul>
          <p id="footnote">{footnote2}</p>
        </div>
        <div className="hero-image-container">
          <img
            className="hero-image"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/hero-dog-brown.png"
            alt="brown dog"
          />
        </div>
      </div>
    </div>
  );
};
export default Postbiotics;
