import React, { useContext, useState, useEffect } from "react";
import { TopicContext } from "./TopicContext";
import "./intro-slide.css";
import http from "../lib/http";

export const IntroSlide = () => {
  // eslint-disable-next-line
  const { topicType, setTopicType, topicChosen, setTopicChosen } =
    useContext(TopicContext);

  const handleClick = (e) => {
    setTopicType((topic) => e.target.id);
    setTopicChosen((topic) => true);
    console.log(e.target.parentNode.id);
  };

  let interactiveId = 7;
  const [introText, setIntroText] = useState();
  const [introTitle, setIntroTitle] = useState();
  useEffect(() => {
    async function fetchData() {
      const { data } = await http.get(`/api/items/${interactiveId}`);
      setIntroText(data.item.sceneSelectBody);
      setIntroTitle(data.item.sceneSelectHeadline);
    }
    fetchData();
  });

  return (
    <div className="intro-screen">
      <div className="intro-text">
        <h1>{introTitle}</h1>
        <h2>{introText}</h2>
      </div>
      <div className="topic-section-container">
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="gut-brain-axis"
        >
          <img
            className="topic-image"
            alt="bifidovacteria"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/background-bifidobacterium.jpg"
          />
          <button className="topic-button">
            <h3>Gut-Brain Axis</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="probiotics"
        >
          <img
            className="topic-image"
            alt="probiotic bacteria"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/thumbnail-probiotic.png"
          />
          <button className="topic-button">
            <h3>Probiotics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="prebiotics"
        >
          <img
            className="topic-image"
            alt="Prebiotic bacteria"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/thumbnail-blue-prebiotic.png"
          />
          <button className="topic-button">
            <h3>Prebiotics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="postbiotics"
        >
          <img
            className="topic-image"
            alt="postbiotic bacteria"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/background-postbiotics.jpg"
          />
          <button className="topic-button">
            <h3>Postbiotics</h3>
          </button>
        </div>
        <div
          className="topic-option-container"
          onClick={handleClick}
          id="colostrum"
        >
          <img
            className="topic-image"
            alt="colostrum"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/Colostrum_Header.jpg"
          />
          <button className="topic-button">
            <h3>Colostrum</h3>
          </button>
        </div>
      </div>
    </div>
  );
};
