import React from "react";
import "./slides.css";
import Title from "./Title";

const Probiotics = (slide) => {
  function toggle() {
    document
      .getElementById("central-media-container")
      .classList.toggle("fade-out");
    document.getElementById("primary").classList.toggle("is-visible");
  }

  const scienceOne = slide.data.slides[2].info1;
  const scienceTwo = slide.data.slides[2].info2;
  const scienceThree = slide.data.slides[2].info3;
  const scienceFour = slide.data.slides[2].info4;
  const scienceFive = slide.data.slides[2].info5;
  const scienceSix = slide.data.slides[2].info6;

  const splash = slide.data.slides[3].splash;
  const petOne = slide.data.slides[3].info1;
  const petTwo = slide.data.slides[3].info2;
  const petThree = slide.data.slides[3].info3;
  const petFour = slide.data.slides[3].info4;
  const petFive = slide.data.slides[3].info5;
  const footnote = slide.data.slides[3].footnote;

  const handleClick = () => {
    const primarySlide = document.getElementById("primary");
    const secondarySlide = document.getElementById("secondary");
    primarySlide.classList.toggle("hide");
    primarySlide.classList.toggle("is-visible");
    secondarySlide.classList.toggle("hide");
    secondarySlide.classList.toggle("is-visible");
  };
  return (
    <div className="page-container">
      <Title {...slide} />
      <div className="media-container probiotics" id="primary">
        <button className="switch-panel left" onClick={toggle}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
            alt="previous page"
          />
        </button>
        <h3>An Effective Probiotic...</h3>
        <div className="science-image-container">
          <img
            alt="molecule icon"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/icon-Molecular-Component.png"
          />
          <img
            alt="gut icon"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/icon-Gut-Component.png"
          />
          <img
            alt="bacteria icon"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/icon-Harmful-Bacteria-Component.png"
          />
          <img
            alt="shield icon"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/icon-Shield-Component.png"
          />
        </div>
        <div className="list-container">
          <ul className="data-list">
            <li className="list-item" id="list-item-one">
              {scienceOne}
            </li>
            <li className="list-item" id="list-item-two">
              {scienceTwo}
            </li>
            <li className="list-item" id="list-item-three">
              {scienceThree}
            </li>
            {scienceFour ? (
              <li className="list-item" id="list-item-four">
                {scienceFour}
              </li>
            ) : null}
            {scienceFive ? (
              <li className="list-item" id="list-item-five">
                {scienceFive}
              </li>
            ) : null}
            {scienceSix ? (
              <li className="list-item" id="list-item-six">
                {scienceSix}
              </li>
            ) : null}
          </ul>
        </div>

        <button className="switch-panel right" onClick={handleClick}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Right%20Arrow.png"
            alt="next page"
          />
        </button>
      </div>
      <div className="media-container" id="secondary">
        <button className="switch-panel left" onClick={handleClick}>
          <img
            src="https://storage.googleapis.com/purina-virtual-experience/design/ui/Left%20Arrow.png"
            alt="previous page"
          />
        </button>
        <div className="list-container">
          <ul className="data-list">
            <h4>{splash}</h4>
            <li className="list-item probiotics" id="list-item-one">
              {petOne}
            </li>
            <li className="list-item probiotics" id="list-item-two">
              {petTwo}
            </li>
            <li className="list-item probiotics" id="list-item-three">
              {petThree}
            </li>
            {petFour ? (
              <li className="list-item probiotics" id="list-item-four">
                {petFour}
              </li>
            ) : null}
            {petFive ? (
              <li className="list-item probiotics" id="list-item-five">
                {petFive}
              </li>
            ) : null}
          </ul>
          <p id="footnote">{footnote}</p>
        </div>
        <div className="hero-image-container">
          <img
            className="hero-image"
            src="https://storage.googleapis.com/purina-virtual-experience/07-promoting-gastrointestinal-health/hero-england-terrier.png"
            alt="England, the cute happy terrier"
          />
        </div>
      </div>
    </div>
  );
};

export default Probiotics;
